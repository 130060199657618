import React, { forwardRef } from 'react';
import { keyframes } from '@stitches/react';
import { styled } from 'stitches.config';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { FontAwesomeIcon as BaseFontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Accordion: React.FC = () => (
  <AccordionRoot type="single" collapsible>
    <AccordionItem value="item-1">
      <AccordionTrigger>What is Mintpad?</AccordionTrigger>
      <AccordionContent>Mintpad serves as your gateway to launching CRC20 tokens and NFTs, offering a unique blend of technology and community. It's designed not just for launching your projects but also for connecting you with a ready and willing pool of investors. Our platform is home to a vibrant community of token holders interested in supporting innovative projects. At Mintpad, we're about creating opportunities for creators and developers to thrive in the Cronos ecosystem. </AccordionContent>
    </AccordionItem>

    <AccordionItem value="item-3">
      <AccordionTrigger>How can I launch my CRC20 token on Mintpad?</AccordionTrigger>
      <AccordionContent>
      Anyone can initiate a presale on Mintpad. However, if you're looking for a tailored presale experience, we invite you to get in touch with us. You can reach out to our team via our Discord server, where we ask you to share details about your project.
      </AccordionContent>
    </AccordionItem>

    <AccordionItem value="item-2">
      <AccordionTrigger>What is the future roadmap for MINT</AccordionTrigger>
      <AccordionContent>
      The future roadmap for our MINT token includes plans for further development, integration with other features within our ecosystem, airdrops, staking, and the exploration of additional use cases. Stay updated on the project's website and social media channels for announcements and updates.
      </AccordionContent>
    </AccordionItem>

    <AccordionItem value="item-4">
    <AccordionTrigger>Where can I purchase MINT?</AccordionTrigger>
    <AccordionContent>
      We will share details on our social media channels, including discord server. Furthermore, MINT will also be listed on major DeFi applications on the Cronos network, providing additional avenues for trading and liquidity.
    </AccordionContent>
    </AccordionItem>
  </AccordionRoot>
);

const AccordionRoot = styled(AccordionPrimitive.Root, {
  borderRadius: 6,
  width: '100%',
  backgroundColor: '$panelBg',
  boxShadow: `0 2px 10px $blackA`,
});

const AccordionItem = styled(AccordionPrimitive.Item, {
    borderTopWidth: 0,
    borderBottomWidth: 1,
    borderImage: 'linear-gradient(90deg, rgba(0, 255, 255, 0) 0%, rgb(56 144 230) 50%, rgba(0, 255, 255, 0) 100%) 10 / 1 / 0 stretch',
    overflow: 'hidden',
    marginTop: 1,

    '&:first-child': {
        marginTop: 0,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },

    '&:last-child': {
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },

    '&:focus-within': {
        position: 'relative',
        zIndex: 1,
       // boxShadow: `0 0 0 2px ${mauve.mauve12}`,
    },
});

const FontAwesomeIcon = styled(BaseFontAwesomeIcon, { 
    color: '$gray11',
    transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
    '[data-state=open] &': { transform: 'rotate(180deg)' },
});

const AccordionTrigger = forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement>>(({ children, ...props }, ref) => (
    <StyledHeader>
      <StyledTrigger {...props} ref={ref}>
        {children}
        <FontAwesomeIcon icon={faChevronDown} />
      </StyledTrigger>
    </StyledHeader>
));
  
  const AccordionContent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ children, ...props }, ref) => (
    <StyledContent {...props} ref={ref}>
      <StyledContentText>{children}</StyledContentText>
    </StyledContent>
  ));
  

const StyledHeader = styled(AccordionPrimitive.Header, {
  all: 'unset',
  display: 'flex',
});

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: 'unset',
  fontFamily: 'inherit',
  padding: '1.25rem 1.5rem',
  height: 45,
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 15,
  lineHeight: 1,
  color: '$gray12',
  // boxShadow: `0 1px 0 ${mauve.mauve6}`,
  backgroundColor: '$neutralBg',
  '&:hover': { cursor:'pointer'},
});

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

const StyledContent = styled(AccordionPrimitive.Content, {
    overflow: 'hidden',
    fontSize: 15,
    backgroundColor: '$neutralBg',
    '&[data-state="open"]': {
        animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
    },
    '&[data-state="closed"]': {
        animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
    },
});

const StyledContentText = styled('div', {
  padding: '15px 20px',
  color:'$gray10'
});

export default Accordion;