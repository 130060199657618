import React, { useEffect, useState } from 'react';
import { faChevronLeft, faChevronRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Flex, FormatCryptoCurrency, Text } from 'components/primitives';
import Img from 'components/primitives/Img';
import { useIsUnsupportedChain } from 'hooks';
import Link from 'next/link';
import { OpenSeaVerified } from 'components/common/OpenSeaVerified';
import { PresaleToken } from 'config/types';
import { useMultiContract } from 'hooks/useMultiContract';
import { Abi, Chain, formatUnits, zeroAddress } from 'viem';
import { PresaleAbi } from 'config/abis/PresaleAbi';
import Tag from 'components/primitives/Tag';

type FeaturedPresaleCardsProps = {
  collections: PresaleToken[];
  loading?: boolean;
};

export const FeaturedPresaleCards: React.FC<FeaturedPresaleCardsProps> = ({
  collections,
  loading,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [moveForward, setMoveForward] = useState(true); 
  const { preferredNetwork:chain } = useIsUnsupportedChain()
  const presaleAddresses = collections.map((collection) => collection.presaleAddress);
  const {
    data: contributionsData,
  }= useMultiContract(chain as Chain, PresaleAbi as Abi, presaleAddresses, 'totalContributed', 18)
  
  const currentTime = Math.floor(new Date().getTime() / 1000);

  collections.forEach((collection, index) => {
    const totalContributedData = contributionsData?.[index];
    const totalContributed = totalContributedData
        ? typeof totalContributedData.result === 'bigint'
          ? +formatUnits(totalContributedData.result, 18)
          : totalContributedData.result ?? 0
        : 0;
    collection.totalContributed = Number(totalContributed ||  0);
  });

  const moveToNextSlide = () => {
    if (collections && currentSlide < collections.length - 1) {
      setCurrentSlide((prevSlide) => prevSlide + 1);
    }
  };

  const moveToPrevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prevSlide) => prevSlide - 1);
    }
  };

  if (!collections || collections.length === 0) return null;

  const isOnFirstSlide = currentSlide === 0;
  const isOnLastSlide = currentSlide === collections.length - 1;

  const showArrows = collections.length > 1; 

  // useEffect hook for auto-moving slides
  useEffect(() => {
    if(collections.length > 1){
      const intervalId = setInterval(() => {
        if (moveForward) {
          if (currentSlide < collections.length - 1) {
            setCurrentSlide(currentSlide + 1);
          } else {
            setMoveForward(false); // Reached the end, switch direction
          }
        } else {
          if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
          } else {
            setMoveForward(true); // Reached the start, switch direction
          }
        }
      }, 5000); // Move slide every 5 seconds

      // Cleanup on component unmount
      return () => clearInterval(intervalId);
   }
  }, [currentSlide, moveForward, collections.length]); 

  return (
    <>
      {!loading && collections.length === 0 ? (
        <Flex
          direction="column"
          align="center"
          css={{ py: '$6', gap: '$4', width: '100%' }}
        >
          <Text css={{ color: '$gray11' }}>
            <FontAwesomeIcon icon={faMagnifyingGlass} size="2xl" />
          </Text>
          <Text css={{ color: '$gray11' }}>No collections found</Text>
        </Flex>
      ) : (
      <Box
        css={{
          position: 'relative',
          width: '100%',
          overflow: 'hidden',
          padding: '10px 0',
          '&:hover .slider-arrows': {
            display: 'flex',
          },
        }}
      >
        <Box
          css={{
            display: 'flex',
            transition: 'transform 0.5s ease',
            transform: `translateX(-${currentSlide * 100}%)`,
            gap: 12,
            width: 341
          }}
        >
          {collections.map((collection) => (
            <Link
              key={collection.id}
              href={`/presale/${collection.presaleAddress}`}
            >
              <Flex
                direction="column"
                css={{
                  flex: 1,
                  width: '330px',
                  height: '290px',
                  borderRadius: 12,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: '$gray5',
                  cursor: 'pointer',
                  background: '$neutralBg',
                  $$shadowColor: '$colors$panelShadow',
                  boxShadow: '0px 0px 12px 0px $$shadowColor',
                  p: '16px',
                }}
              >
                <Flex
                  css={{
                    mb: '24px',
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                  }}
                >
                  <Flex
                    css={{
                      height: '150px',
                      width: '300px',
                    }}
                  >
                    <Img
                      src={
                        collection?.banner as string
                      }
                      alt={collection.name as string}
                      height={150}
                      width={300}
                      style={{
                        objectFit: 'cover',
                        borderRadius: 8,
                      }}
                    />
                  </Flex>
                  <Img
                    src={collection?.image as string}
                    alt={collection.name as string}
                    height={50}
                    width={50}
                    css={{
                      height: '50px',
                      width: '50px',
                      position: 'absolute',
                      inset: '95px 0px 5px 5px',
                     // border: '2px solid white',
                      border: 'none',
                      borderRadius: 8,
                    }}
                  />
                </Flex>
                <Flex
                  direction="column"
                  css={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Box
                    css={{
                      maxWidth: 720,
                      lineHeight: 1.5,
                      fontSize: 16,
                      flex: 1,
                      fontWeight: 400,
                      display: '-webkit-box',
                      color: '$gray12',
                      fontFamily: '$body',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      gap: 16,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      '& a': {
                        fontWeight: 500,
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    <Flex
                      direction="row" justify={'between'}
                      css={{width: '100%'}}
                    >
                      <Flex
                        align="center"
                        css={{
                          width: 'fit-content',
                          mb: 16,
                          gap: '$2',
                        }}
                      >
                        <Text style="h6" as="h6" ellipsify>
                          {collection?.name}
                        </Text>
                        <OpenSeaVerified openseaVerificationStatus={collection.status as string} />
                      </Flex>
                        {currentTime > Number(collection.startTime) && (
                          <Flex css={{}}>
                            <Tag css={{minWidth:120, border:'none'}}
                              message={currentTime > Number(collection.endTime) ? 'Ended' : 'Live Now'}
                              variant={currentTime > Number(collection.endTime)  ? 'error' : 'success'} // Set variant based on soldOut prop
                              icon={false}
                            />
                          </Flex>
                        )}
                    </Flex>
                    <Flex>
                      <Box css={{ mr: '$5' }}>
                        <Text
                          style="subtitle2"
                          color="subtle"
                          as="p"
                          css={{ mb: 2 }}
                        >
                          Total Raised
                        </Text>
                        <FormatCryptoCurrency
                          amount={collection.totalContributed}
                          textStyle={'h6'}
                          logoHeight={16}
                          address={zeroAddress}
                        />
                      </Box>

                      <Box css={{ mr: '$4' }}>
                        <Text style="subtitle2" color="subtle" as="p">
                          Sale Supply
                        </Text>
                        <Text style="h6" as="h4" css={{ mt: 2 }}>
                          {collection.saleSupply?.toLocaleString()}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
            </Link>
          ))}
        </Box>
        {showArrows && (
          <Box
            className="slider-arrows"
            css={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              display: 'flex',
              justifyContent: isOnLastSlide ? 'flex-end' : 'space-between', // Adjusted justification
              width: '100%',
              padding: '0 10px',
            }}
          >
            {!isOnFirstSlide && (
              <Button
                onClick={moveToPrevSlide}
                corners={'circle'}
                color={'gray3'}
                css={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '24px',
                  color: '$gray11',
                  position: 'absolute',
                  left: 0,
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
            )}
            {!isOnLastSlide && (
              <Button
                onClick={moveToNextSlide}
                corners={'circle'}
                color={'gray3'}
                css={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '24px',
                  color: '$gray11',
                  position: 'absolute',
                  right: 0,
                }}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            )}
          </Box>
        )}
      </Box>
      )}
    </>
  )
}