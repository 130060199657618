import { Box, Flex, Text } from 'components/primitives'
import { useTheme } from 'next-themes'
import { FC } from 'react'
import useMediaQuery from 'hooks/useMediaQuery'

interface IProp {
  hideLink?: boolean
}


const HeroSection: FC<IProp> = ({ hideLink }) => {
  const isMobile = useMediaQuery('(max-width: 520px)')
  const { theme } = useTheme()
  return (
    <Flex
      css={{
        position: 'relative',
      //  inlineSize: '97vw',
       // aspectRatio: '1111/1111',
        width: '100%',
        maxWidth:980,
        margin:'auto',
        pt:'$5',
        '@sm': {
        pt: '$2',
        },
      //  '@bp500': {aspectRatio: '2300/1180'}
      }}
    >
        <Box
            css={{
            //  opacity: 0.3,
            //  backgroundImage: 'radial-gradient(50% 50% at 50% 50%, rgba(151, 71, 255, 0.4) 0%, rgba(151, 71, 255, 0) 100%)',
           //   backgroundImage:'radial-gradient(50% 50% at 50% 50%, rgb(71 117 255 / 40%) 0%, rgb(71 124 255 / 0%) 100%)',
            //    backgroundImage: `url(/home/dots.jpg)`,
           //     backgroundSize: isMobile? 'auto 100%' : '100% auto',
           //     backgroundRepeat:'no-repeat',
           //     backgroundPosition: 'center center',
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
             //   transform: 'scale(-1)',
            }}
        />
        <Box
            css={{
                p: 24,
                minHeight:150,
                height: '100%',
                width: '100%',
                '@bp800': {
                px: '$5',
                },
                '@xl': {
                px: '$6',
                },
            }}
        >
          <Flex direction={'column'} align={'center'} justify={'center'}
            css={{
              position:'relative',
              width:'auto',
              height: '100%',
              maxWidth: 650,
              margin:'auto',
              gap:'$2'
            }}
          >
          <Text
            as="h3"
            css={{
              fontSize: '2.5rem',
              fontWeight: 700,
              background: 'linear-gradient(45deg, rgb(49, 98, 230), rgb(128, 219, 198) 80%)',
              // radial-gradient(50% 50% at 50% 50%, rgb(24 35 43) 0%, rgb(71 124 255 / 0%) 100%)
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              '@media (min-width: 768px)': {
                fontSize: '5rem',
              },
            }}
          >
            Mintpad
          </Text>
            {/*
              <Text style="h4" as="h4" css={{ flexShrink: 0 }}>
                <Text style="h4" css={{ color: '$primary9'}}>Discover</Text> presale opportunities
              </Text>
            */}
            <Text style="body1" color="subtle" css={{textAlign:'center'}}>
             Your go-to platform for launching CRC20 tokens, and NFTs.
            </Text>
          </Flex>
        </Box>
    </Flex>
  )
}

export default HeroSection