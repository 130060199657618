import useMediaQuery from 'hooks/useMediaQuery'
import React from 'react'
import Marquee from 'react-fast-marquee'
import { styled } from 'stitches.config'
import { Text } from 'components/primitives'

const TagWrapper = styled('div', {
  background:'$gray2',
  display: 'flex',
  padding: '8px 12px',
  height: 38,
  justifyContent: 'start',
  alignItems: 'center',
  borderRadius: 8,
  marginRight: 8,
  overflow:'hidden',
  '@sm': {
    padding: '12px 24px',
    height: 46,
    marginRight: 12,
  },
});

const StyledMarquee = styled(Marquee, {
  width: '100%',
  maxWidth: 1063,
  maskImage: 'linear-gradient(to left, transparent, black 80px, black calc(100% - 80px), transparent)',
  WebkitMaskImage: 'linear-gradient(to left, transparent, black 80px, black calc(100% - 80px), transparent)',
  borderRadius: 12,
});


const newsItems = [

  {
    key: 'Partners 1',
    iconWidth: '26px',
  },
  {
    key: 'Partners 2',
    iconWidth: '20px',
  },
  {
    key: 'Partners 3',
    iconWidth: '18px',
  },
]

export const Tags: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 520px)')
  return (
    <StyledMarquee>
      {newsItems.map((d:any) => (
        <TagWrapper key={d.key}>
          <Text css={{fontWeight:500, fontSize: isMobile ? '16px' : '20px', ml: 10, color: d?.color ?? 'invertedContrast' }}>
            {d.key}
          </Text>
        </TagWrapper>
      ))}
    </StyledMarquee>
  )
}
