import { Abi, Address, Chain, formatUnits } from 'viem';
import { useReadContracts } from 'wagmi';

export const useMultiContract = (
  chain: Chain,
  abi: Abi,
  addresses: Address[],
  functionName: string,
  decimals?: number,
  args?: any []
) => {
  const contracts = addresses.map(address => ({
    address,
    abi,
    functionName,
    chainId: chain?.id,
    args
  }));

  const {
    data,
    isFetching,
    refetch,
    isFetchedAfterMount,
  } = useReadContracts({ contracts });

  return {
    data,
    isFetching,
    refetch,
    isFetchedAfterMount,
  };
};