import { Head } from 'components/Head'
import Layout from 'components/Layout'
import { Footer } from 'components/home/Footer'
import { Box, Flex, Text } from 'components/primitives'
import { ChainContext } from 'context/ChainContextProvider'
import {  NextPage } from 'next'
import {
  useContext,
  useEffect,
  useState,
} from 'react'
import supportedChains from 'utils/chains'
import { useTheme } from 'next-themes'
import { useRouter } from 'next/router'
import HeroSection from 'components/home/Hero'
import Accordion from 'components/primitives/Accordion'
import { PresaleToken } from 'config/types'
import PresaleTokens from 'config/presale'
import { FeaturedPresaleCards } from 'components/home/FeaturedPresaleCards'
import { Tags } from 'components/home/PartnersTags'
import { useMounted } from 'hooks'


const Home: NextPage = () => {
  const router = useRouter();
  const isMounted = useMounted();
  // not sure if there is a better way to fix this
  const { theme: nextTheme } = useTheme()
  const [theme, setTheme] = useState<string | null>(null)
  useEffect(() => {
    if (nextTheme) {
      setTheme(nextTheme)
    }
  }, [nextTheme])

  const { chain, switchCurrentChain } = useContext(ChainContext)

  useEffect(() => {
    if (router.query.chain) {
      let chainIndex: number | undefined
      for (let i = 0; i < supportedChains.length; i++) {
        if (supportedChains[i].routePrefix == router.query.chain) {
          chainIndex = supportedChains[i].id
        }
      }
      if (chainIndex !== -1 && chainIndex) {
        switchCurrentChain(chainIndex)
      }
    }
  }, [router.query])

  const now = Math.floor(Date.now() / 1000);
  let filteredCollections: PresaleToken[] = PresaleTokens.filter((collection) => collection.featured);


  return (
    <Layout>
      <Head />
      <Flex css={{
        alignItems:'center', 
        width:'100%', 
        background: theme === 'light' ? '$neutralBg' : 'linear-gradient($neutralBg, rgb(24 35 43))'
        }}>
        <HeroSection/>
      </Flex>
      <Box css={{
          p: 10,
          my:'$5',
          justifyContent:'center',
          alignItems:'center', 
          height: '100%',
          '@bp800': {
            px: '$5',
          },
          '@xl': {
            px: '$6',
          },
          '@sm': {
            p: 24,
          },
        }}>
        <Box
            css={{
              width: '100%',
              maxWidth:1024,
              margin:'auto',
            }}
        >
          <Flex
            justify="between"
            align="start"
            css={{
              gap: 24,
            }}
          >
            {filteredCollections?.length > 0 && (
            <Text style="h4" as="h4">
              Featured
            </Text>
            )}
          </Flex>
          <Box
            css={{
              height: '100%',
            }}
          >
            <FeaturedPresaleCards collections={filteredCollections} />
          </Box>
        </Box>
      </Box>
      <Box
        css={{
          p: 10,
          my:'$6',
          justifyContent:'center',
          alignItems:'center', 
          height: '100%',
          '@bp800': {
            px: '$5',
          },
          '@xl': {
            px: '$6',
          },
          '@sm': {
            p: 24,
            my:'$5',
          },
        }}
      >
        <Flex css={{
            p:'$2',
            borderRadius:8,
            borderTop: '1px solid #378ce5',
            background: '$neutralBg',
            position:'relative',
            width: '100%',
            maxWidth:950,
            margin:'auto',
            marginTop: -50,
            '@sm': {
              p: '$5',
            },
          }}
        >
           {isMounted && (<Accordion />)}
        </Flex>
        <Flex css={{
            p:'$2',
            position:'relative',
            width: '100%',
            maxWidth:950,
            margin:'auto',
            marginTop: 50,
            '@sm': {
              p: '$5',
            },
          }}
        >
          <Tags/>
        </Flex>
      </Box>
      <Footer />
    </Layout>
  )
}

export default Home
